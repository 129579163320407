.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.grid > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 20px;
  background-color: #18232c;
}

.grid > div > div:first-child {
  font-size: 24px;
  font-weight: bold;
  color: #bbc6ce;
}

.grid > div > div:last-child {
  margin-top: 10px;
  font-size: 16px;
  color: #bbc6ce;
}
