@media (max-width: 1200px) {
  #login-image {
    display: none;
  }
}
@media (min-width: 1200px) {
  #login-image {
    display: block;
  }
}
